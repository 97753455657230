<template>
  <b-dropdown
    :id="name"
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
    dropup
    :disabled="disabled"
  >
    <template slot="button-content">
      <feather-icon
        :icon="icon"
        size="16"
      />
    </template>
    <slot />
  </b-dropdown>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BDropdown } from 'bootstrap-vue'

export default {
  name: 'AmDropdown',
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
  },
  props: {
    disabled: { type: Boolean, default: false },
    name: { type: String, default: null },
    icon: { type: String, default: 'MoreVerticalIcon' },
  },
}
</script>

<style scoped>

</style>
