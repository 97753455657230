// eslint-disable-next-line import/prefer-default-export
export function formatName(name) {
  return name.replaceAll('_', ' ')
}
export function parseValidationErrors(errors) {
  const parsedErrors = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(errors)) {
    const keyList = key.split('.')
    const cleanKey = keyList[keyList.length - 1]
    const replace = keyList.length > 1 ? `${keyList[0]}.` : ''
    parsedErrors[cleanKey] = value.toString().replace(replace, '')
  }
  return parsedErrors
}
export function parseAndMergeErrors(errors) {
  const parsedErrors = parseValidationErrors(errors)
  return Object.values(parsedErrors)
}
