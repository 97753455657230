<template>
  <b-modal
    v-model="showModal"
    scrollable
    content-class="shadow"
    :title="title"
    :ok-title="okText"
    :cancel-title="cancelText"
    :hide-footer="hideFooter"
    :size="size"
    :modal-class="`modal-${variant}`"
    :ok-disabled="okDisabled"
    :class="extraClasses"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @ok="handleOkClick"
    @hide="handleClose"
  >
    <b-overlay
      id="am_modal_content"
      :show="showOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      :variant="isDarkMode?'dark':'white'"
      opacity="0.8"
    >
      <b-card-text style="overflow-x: hidden;overflow-y: hidden">
        <slot />
        <div
          class="mt-1"
          style="visibility: hidden"
        >
          Empty Space
        </div>
      </b-card-text>
    </b-overlay>
    <template slot="modal-footer">
      <slot name="modal-actions">
        <b-row class="float-right">
          <am-button
            v-if="!okOnly"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            :label="cancelText"
            @click="handleClose"
          />
          <am-button
            :label="okText"
            :disabled="okDisabled"
            :loading="loading || showOverlay"
            :loading-text="loadingText"
            @click="handleOkClick"
          />
        </b-row>
      </slot>
    </template>

  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BRow, BOverlay,
} from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'

export default {
  name: 'AmModal',
  components: {
    AmButton,
    BModal,
    BCardText,
    BRow,
    BOverlay,
    // BCol,
  },
  props: {
    title: { type: String, default: 'Modal' },
    okText: { type: String, default: 'Yes' },
    loadingText: { type: String, default: 'Loading...' },
    cancelText: { type: String, default: 'Cancel' },
    size: { type: String, default: 'sm' },
    variant: { type: String, default: 'primary' },
    extraClasses: { type: String, default: '' },
    okOnly: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    okDisabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showOverlay: { type: Boolean, default: false },
    noCloseOnBackdrop: { type: Boolean, default: true },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    isDarkMode() {
      return this.$store.getters['appConfig/skin'] === 'dark'
    },
  },
  watch: {
    show(val) {
      this.showModal = val
    },
  },
  methods: {
    handleOkClick() {
      this.$emit('onOk')
    },
    handleClose() {
      this.$emit('onClose')
    },
  },
}
</script>

<style>
  /* width */
  #am_modal_content::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
 #am_modal_content::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
 #am_modal_content::-webkit-scrollbar-thumb {
    background: linear-gradient(#d4d4d4, #636363);
    border-radius: 10px;
  }

  /* Handle on hover */
 #am_modal_content::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(#d4d4d4, #636363);
    border-radius: 10px;
  }
</style>
