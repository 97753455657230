<template>
  <validation-provider
    ref="amDate"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="label && showLabel"
        :for="`date-${name}`"
      ><span v-html="$t(label)" /> <b
        v-if="isRequired"
        class="text-danger"
        style="font-size: 15px"
      >*</b></label>
      <!--      <b-input-group>-->
      <flat-pickr
        :id="`date-${name}`"
        :name="name"
        class="form-control"
        :placeholder="label"
        :config="{altInput: true,noCalendar: hideCalendar, altFormat: enableTime?hideCalendar?'H:i':'F j, Y, H:i':'F j, Y', enableTime: enableTime,dateFormat: enableTime?hideCalendar?'H:i':'Y-m-d H:i':'Y-m-d'}"
        :value="value"
        mode="range"
        @input="inputChanged"
      />
      <!--        <b-input-group-append-->
      <!--          is-text-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            class="cursor-pointer"-->
      <!--            icon="CalendarIcon"-->
      <!--          />-->
      <!--        </b-input-group-append>-->

      <!--      </b-input-group>-->
      <small
        v-if="showError"
        class="text-danger text-capitalize"
      >{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import {
  BFormGroup,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import { required, noFutureDate, noPastDate } from '@core/utils/validations/validations'

export default {
  name: 'AmDatePicker',
  components: {
    BFormGroup,
    ValidationProvider,
    flatPickr,
  },
  props: {
    name: {
      type: String,
      default: 'date',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    showError: { type: Boolean, default: true },
    enableTime: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    hideCalendar: { type: Boolean, default: false },
  },
  data() {
    return {
      required,
      noFutureDate,
      noPastDate,
      errors: [],
    }
  },
  computed: {
    isRequired() {
      return this.rules.includes('required')
    },
  },
  watch: {
    value(val) {
      // this.$refs.amDate.validate().then(res => {
      //   this.errors = res.errors
      // })
    },
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    inputChanged(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
