<template>
  <!--    Contribution Form-->
  <validation-observer
    ref="contributionPaymentForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateContributionMode?'Update':'Record'} Payment`"
      :show="contributionModalOpened"
      :show-overlay="deletingContribution"
      size="md"
      @onClose="closeContributionModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedContributionPaymentId"
            placeholder="All Payments"
            :options="contributionPayments"
            label-name="payment_label"
            value-name="id"
            name="contributions"
            @input="populateContributionForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            icon="TrashIcon"
            name="delete_contribution"
          >
            <b-dropdown-item
              id="cd_contribution"
              @click="deleteContribution()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="contribution_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetContributionForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-select
        v-model="contribution.contribution_id"
        name="contribution_id"
        label="Contribution"
        rules="required"
        label-name="setting_label"
        value-name="id"
        :options="contributions"
      />
      <am-input
        v-model="contribution.contributor_id"
        name="contributor"
        label="Contributor ID"
        rules="required"
        suffix-icon="SearchIcon"
        @onSuffixIconClick="getContributorDetails"
        @onEnterPressed="getContributorDetails"
      />
      <div
        v-if="contributor"
        class="d-flex align-items-center mb-1"
      >
        <am-profile-photo
          size="30"
          :photo="contributor.photo"
        />
        <div style="margin-right: 5px;" />
        <b-badge variant="light-primary">
          {{ contributor.name }}
        </b-badge>
        <div
          style="margin-right: 5px;"
        />
        <b-badge
          v-if="contributor.amount>0"
          variant="light-danger"
        >
          {{ currencySymbol }}{{ contributor.amount }}
        </b-badge>
        <b-badge
          v-else
          variant="light-primary"
        >
          Fully Paid
        </b-badge>
        <am-button
          icon-only
          variant="flat-danger"
          icon="XIcon"
          @click="clearContributor()"
        />
      </div>

      <am-input
        v-model="contribution.amount"
        name="amount"
        :label="`Amount (${currencySymbol})`"
        :rules="updateContributionMode?`required|numeric`:`required|numeric|max_value:${pendingAmount}`"
      />
      <am-input
        v-model="contribution.payer_name"
        name="payer_name"
        label="Payer Name"
        rules="required"
      />
      <am-input
        v-model="contribution.payer_phone"
        name="payer_phone"
        label="Payer Phone"
        rules="required"
      />
      <am-date-picker
        id="payment_date"
        v-model="contribution.payment_date"
        name="payment_date"
        label="Payment Date"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeContributionModal()"
          />
          <am-button
            name="save_contribution"
            :label="updateContributionMode?'Update':'Record'"
            :disabled="invalid"
            :loading="contributionLoading"
            loading-text="Loading..."
            @click="updateContributionMode?updateContribution():createContribution()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmProfilePhoto from '@/farmbusiness/components/AmProfilePhoto.vue'

const cont = {
  id: null,
  contribution_id: null,
  contributor_id: null,
  is_member_payment: false,
  amount: null,
  payment_date: null,
  payer_name: null,
  payer_phone: null,
}
export default {
  name: 'ContributionPaymentSetup',
  components: {
    AmProfilePhoto,
    AmDatePicker,
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmSelect,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      contributionModalOpened: false,
      updateContributionMode: false,
      contributionLoading: false,
      deletingContribution: false,
      selectedContributionPaymentId: null,
      contribution: { ...cont },
    }
  },
  computed: {
    memberContribution() {
      return this.selectedContributionSetting?.pricing_level?.includes('Member')
    },
    currencySymbol() {
      return 'GHS'
    },
    selectedContributionSetting() {
      return this.contributions.find(o => +o.id === +this.contribution.contribution_id)
    },
    selectedContributionPayment() {
      const contribution = this.contributionPayments.find(o => o.id === this.selectedContributionPaymentId)
      if (!contribution) {
        return null
      }
      return {
        ...contribution,
      }
    },
    paymentSettingId() {
      return this.$store.getters['contributionPayments/paymentSettingId']
    },
    contributions() {
      return this.$store.getters['contributionSettings/contributionSettings']
    },
    contributionPayments() {
      return this.$store.getters['contributionPayments/contributionPayments']
    },
    contributor() {
      return this.$store.getters['contributionPayments/contributor']
    },
    pendingAmount() {
      if (this.contributor) {
        return this.contributor.amount
      }
      return 0
    },
  },
  watch: {
    modalOpened(opened) {
      this.contributionModalOpened = opened
      this.clearContributor()
    },
    id(id) {
      this.selectedContributionPaymentId = id
    },
    paymentSettingId(val) {
      this.contribution.contribution_id = val
    },
  },
  mounted() {
    this.contribution.contribution_id = this.paymentSettingId
  },
  methods: {
    clearContributor() {
      this.$store.commit('contributionPayments/setContributor', null)
    },
    resetContributionForm() {
      this.contribution = { ...cont }
      this.selectedContributionPaymentId = null
      this.updateContributionMode = false
      this.$refs.contributionPaymentForm.reset()
      this.clearContributor()
    },
    populateContributionForm(contribution) {
      if (this.selectedContributionPayment) {
        this.updateContributionMode = true
        this.contribution = { ...this.selectedContributionPayment }
      } else {
        this.resetContributionForm()
      }
    },
    closeContributionModal() {
      this.contributionModalOpened = false
      this.$emit('modalClosed')
    },
    getContributorDetails() {
      const id = this.contribution.contributor_id
      if (id) {
        this.$store.dispatch('contributionPayments/getAmountToPay', {
          contributor_id: id,
          contribution_id: this.contribution.contribution_id,
          is_member: this.memberContribution,
        })
      } else {
        this.showInfo('Enter Contributor ID')
      }
    },
    createContribution() {
      this.error = {}
      this.contributionLoading = true
      this.contribution.is_member_payment = this.memberContribution
      this.$store.dispatch('contributionPayments/createContribution', this.contribution)
        .then(res => {
          this.showSuccess('Created contribution successfully')
          this.resetContributionForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create contribution')
        }).finally(() => {
          this.contributionLoading = false
        })
    },
    updateContribution() {
      this.error = {}
      if (!this.contribution.id) {
        return
      }
      this.contributionLoading = true
      if (!this.contribution.logo) {
        delete this.contribution.logo
      }
      this.$store.dispatch('contributionPayments/updateContribution', this.contribution)
        .then(res => {
          this.showSuccess('Updated contribution successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update contribution')
        }).finally(() => {
          this.contributionLoading = false
        })
    },
    deleteContribution() {
      if (!this.selectedContributionPaymentId) {
        this.showInfo('Please select a contribution')
        return
      }
      this.deletingContribution = true
      this.$store.dispatch('contributionPayments/deleteContribution', this.selectedContributionPaymentId)
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.resetContributionForm()
        })
        .catch(() => {
          this.showError('Failed to delete contribution')
        }).finally(() => {
          this.deletingContribution = false
        })
    },
  },
}
</script>
