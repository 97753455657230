<template>
  <am-modal
    :title="`Contribution History (${contributor})`"
    :show="contributionModalOpened"
    :show-overlay="formLoading"
    size="lg"
    hide-footer
    @onClose="closeContributionModal()"
  >
    <b-tabs>
      <b-tab
        active
        title="All Payments"
      >
        <am-table
          :rows="contributionHistory.all_payments"
          :columns="paymentsColumns"
          enable-multi-select
          @onRowSelect="handleRowSelect"
        >
          <template slot="right-extra-header-actions">
            <div
              class="d-flex align-self-center align-items-start mb-1"
            >
              <am-button
                variant="outline-primary"
                icon="PrinterIcon"
                label="Receipts"
                tool-tip-text="Generate receipts for selected payments"
                @click="generateReceipts()"
              />
            </div>
          </template>
        </am-table>
      </b-tab>
      <b-tab
        title="All Outstanding"
      >
        <am-table
          :rows="contributionHistory.all_outstanding"
          :columns="outstandingColumns"
        >
          <template slot="table-header">
            <div class="d-flex flex-row full-width mb-1">
              <div class="ml-1">
                <strong>Total Expected:
                  <b-badge
                    variant="light-primary"
                    style="font-size: 15px;"
                  >
                    <small>{{ currencySymbol }}</small>{{ totalExpectedAmount | currency }}
                  </b-badge>
                </strong>
              </div>
              <div class="ml-1">
                <strong>Total Collected:
                  <b-badge
                    variant="light-success"
                    style="font-size: 15px;"
                  >
                    <small>{{ currencySymbol }}</small>+{{ totalCollectedAmount | currency }}
                  </b-badge>
                </strong>
              </div>
              <div class="ml-1">
                <strong>Total Outstanding:
                  <b-badge
                    variant="light-danger"
                    style="font-size: 15px;"
                  >
                    <small>{{ currencySymbol }}</small>-{{ totalPendingAmount | currency }}
                  </b-badge>
                </strong>
              </div>
            </div>
          </template>
        </am-table>
      </b-tab>
    </b-tabs>
  </am-modal>
</template>

<script>
import {
  BTabs, BTab, BBadge, BTable,
} from 'bootstrap-vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmTable from '@/farmbusiness/AmTable.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import downloader from '@/farmbusiness/mixins/downloader'

export default {
  name: 'ContributionHistory',
  components: {
    AmButton,
    AmTable,
    AmModal,
    BBadge,
    BTabs,
    BTab,
    BTable,
  },
  mixins: [showToast, downloader],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    contributor: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      contributionModalOpened: false,
      updateContributionMode: false,
      contributorId: null,
      formLoading: false,
      selectedPayments: [],
    }
  },
  computed: {
    totalExpectedAmount() {
      return this.contributionHistory.all_outstanding.reduce((accumulator, currentValue) => accumulator + (+currentValue.expected.replaceAll(',', '').trim()), 0)
    },
    totalCollectedAmount() {
      return this.contributionHistory.all_outstanding.reduce((accumulator, currentValue) => accumulator + (+currentValue.paid.replaceAll(',', '').trim()), 0)
    },
    totalPendingAmount() {
      return this.contributionHistory.all_outstanding.reduce((accumulator, currentValue) => accumulator + (+currentValue.outstanding.replaceAll(',', '').trim()), 0)
    },
    paymentsColumns() {
      const symbol = this.currencySymbol
      return [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'payment_id',
          label: 'Receipt ID',
        },
        {
          key: 'title',
          label: 'Contribution',
        },
        {
          key: 'amount',
          label: `Amount Paid(${symbol})`,
        },
        {
          key: 'payment_mode',
          label: 'Mode of Payment',
        },
        {
          key: 'payment_date',
          label: 'Payment Date',
        },
      ]
    },
    outstandingColumns() {
      const symbol = this.currencySymbol
      return [
        {
          key: 'title',
          label: 'Contribution',
        },
        {
          key: 'expected',
          label: `Expected Payment(${symbol})`,
        },
        {
          key: 'paid',
          label: `Amount Paid(${symbol})`,
        },
        {
          key: 'outstanding',
          label: `Outstanding Amount(${symbol})`,
        },
      ]
    },
    contributionHistory() {
      return this.$store.getters['contributionPayments/contributionHistory']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
  },
  watch: {
    modalOpened(opened) {
      this.contributionModalOpened = opened
    },
  },
  mounted() {
  },
  methods: {
    handleRowSelect(items) {
      this.selectedPayments = items
    },
    closeContributionModal() {
      this.contributionModalOpened = false
      this.$emit('modalClosed')
    },
    generateReceipts() {
      if (this.selectedPayments.length) {
        this.formLoading = true
        this.$store.dispatch('contributionPayments/generateContributorReceipts',
          { id: this.id, ids: this.selectedPayments.map(p => p.id), type: this.type })
          .then(url => {
            if (url) {
              this.downloadFile(url, 'Payment Receipts')
            }
          }).catch(err => {
            this.showError('Failed to generate receipts')
          })
          .finally(() => {
            this.formLoading = false
          })
      } else {
        this.showInfo('Please select at least one payment')
      }
    },
  },
}
</script>
