<template>
  <div class="">
    <am-list-page
      title="Outstanding Contributions"
      :columns="columns"
      :rows="outstanding"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      show-profile-photo
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-select
            v-model="selectedContributionId"
            placeholder="Select Contribution"
            class="mr-1"
            label-name="setting_label"
            value-name="id"
            :options="contributions"
            style="width: 200px !important;"
          />
          <am-button
            variant="outline-primary"
            icon="UserPlusIcon"
            label="Request Payments"
            tool-tip-text="Request organizations & members to make payments directly online."
            class="mr-1"
            @click="requestPayments()"
          />
        </div>
      </template>
      <template #action="{row}">
        <am-button
          icon="Edit2Icon"
          icon-only
          tool-tip-text="Click to edit"
          variant="flat-primary"
          @click="showPaymentModalForEdit(row.item)"
        />
      </template>
      <template #expected="{row}">
        {{ row.item.expected | currency }}
      </template>
      <template #amount_paid="{row}">
        {{ row.item.amount_paid | currency }}
      </template>
      <template #outstanding="{row}">
        {{ row.item.outstanding | currency }}
      </template>
      <template #action="{row}">
        <am-button
          icon-only
          icon="EyeIcon"
          variant="flat-primary"
          tool-tip-text="View contribution history"
          @click="showContributionHistoryModal(row.item)"
        />
      </template>
    </am-list-page>
    <payment-setup
      :id="selectedPaymentId"
      :modal-opened="paymentsModalOpened"
      @modalClosed="closeModal('payments')"
    />
    <contribution-history
      :id="contributorId"
      :type="contributorType"
      :modal-opened="contributionHistoryModalOpened"
      :contributor="contributorName"
      @modalClosed="closeModal('history')"
    />
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import PaymentSetup from '@/views/contribution/payments/ContributionPaymentSetup.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import confirm from '@/farmbusiness/mixins/confirm'
import ContributionHistory from '@/views/contribution/outstanding/ContributionHistory.vue'

export default {
  name: 'Index',
  components: {
    ContributionHistory,
    AmSelect,
    PaymentSetup,
    AmListPage,
    AmButton,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      paymentsModalOpened: false,
      contributionHistoryModalOpened: false,
      selectedPaymentId: null,
      selectedContributionId: null,
      contributorName: null,
      contributorId: null,
      contributorType: null,
    }
  },
  computed: {
    outstandingSettings() {
      return this.$store.getters['contributionPayments/outstandingSettings']
    },
    outstanding() {
      return this.$store.getters['contributionPayments/outstanding']
    },
    contributions() {
      return this.$store.getters['contributionSettings/contributionSettings']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
    columns() {
      const symbol = this.currencySymbol
      return [
        {
          label: 'Photo',
          key: 'photo',
        },
        {
          label: 'Contributor',
          key: 'name',
        },
        {
          label: `Amount Expected(${symbol})`,
          key: 'expected',
        },
        {
          label: `Amount Paid(${symbol})`,
          key: 'amount_paid',
        },
        {
          label: `Outstanding(${symbol})`,
          key: 'outstanding',
        }, {
          label: 'Actions',
          key: 'action',
        },

      ]
    },
  },
  watch: {
    selectedContributionId(val) {
      this.$store.commit('contributionPayments/setOutstandingSettings', { selectedContributionId: val })
      if (!val) {
        this.$store.commit('contributionPayments/setOutstandingContributors', [])
      } else {
        this.loadOutstanding()
      }
    },
  },
  mounted() {
    this.selectedContributionId = this.outstandingSettings.selectedContributionId
    this.loadOutstanding()
  },

  methods: {
    showPaymentModalForEdit(item) {
      this.selectedPaymentId = item.id
      this.paymentsModalOpened = true
    },
    showContributionHistoryModal(item) {
      this.tableLoading = true
      this.$store.dispatch('contributionPayments/getContributionHistory',
        { id: item.contributor_id, type: item.contributor_type })
        .then(res => {
          this.contributorName = `${item.name},${item.contributor_id}`
          this.contributorId = item.id
          this.contributorType = item.contributor_type
          this.contributionHistoryModalOpened = true
        }).catch(err => {
          this.showError('Failed to load contribution history')
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'payments':
          this.paymentsModalOpened = false
          this.selectedPaymentId = null
          break
        case 'history':
          this.contributionHistoryModalOpened = false
          break
      }
    },
    requestPayments() {
      if (this.selectedContributionId) {
        this.confirmAction('You are about to request payments for the selected contribution,'
            + ' all contributors with outstanding payments will be notified with a payment link to make payment.', 'Ok, Proceed').then(res => {
          if (res) {
            this.tableLoading = true
            this.$store.dispatch('contributionPayments/requestPayments', this.selectedContributionId)
              .then(res => {
                this.showSuccess('Requests are being sent')
              }).catch(err => {
                this.showError('Failed to request payments')
              })
              .finally(() => {
                this.tableLoading = false
              })
          }
        })
      } else {
        this.showInfo('Please select a contribution')
      }
    },
    loadOutstanding() {
      if (this.selectedContributionId) {
        this.tableLoading = true
        this.$store.dispatch('contributionPayments/getContributorsWithOutstandingDues', this.selectedContributionId)
          .finally(() => {
            this.tableLoading = false
          })
      } else {
        this.$store.commit('contributionPayments/setOutstandingContributors', [])
      }
    },
  },
}
</script>

<style scoped>

</style>
